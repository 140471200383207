<template>
  <div class="findUser">
    <div class="form-box">
      <el-form
        :inline="true"
        ref="form"
        :model="form"
        label-width="120px"
        label-position="right"
      >
        <el-form-item label="媒体">
          <el-select size="mini" v-model="form.media" placeholder="選択して下さい">
            <el-option
              v-for="item in mediaArr"
              :key="item.id"
              :label="item.media"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 分类 -->
        <el-form-item label="ジャンル">
          <el-select size="mini" v-model="form.type" placeholder="ジャンルを選択して下さい">
            <el-option
              v-for="item in optionArr"
              :key="item.id"
              :label="item.typeb"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="創作/エッセイ">
          <el-select size="mini" v-model="form.creation" placeholder="選択して下さい">
            <el-option label="創作" value="1"></el-option>
            <el-option label="エッセイ" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="広告/漫画">
          <el-select size="mini" v-model="form.adv" placeholder="選択して下さい">
            <el-option label="受ける" value="1"></el-option>
            <el-option label="受けない" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn-box">
      <el-button size="mini" @click="resetSeach">リセット</el-button>
      <!-- 检索 -->
      <el-button size="mini" type="primary" @click="onSubmit">検索</el-button>
    </div>
    <div class="tab-box">
      <div>検索結果</div>
      <template>
        <el-table :data="tableData" border style="width: 100%">
          <!-- 匹配度 -->
          <el-table-column prop="match" label="マッチング度">
            <template slot-scope="scope">
              <span>{{Math.ceil(scope.row.match * 100) }}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="loginattr" label="属性">
            <template slot-scope="scope">
              <span>{{ scope.row.loginattr === 1 ? "原作者" : "編集者" }}</span>
            </template>
          </el-table-column>
          <!-- 昵称 -->
          <el-table-column prop="nickname" label="ニックネーム"> </el-table-column>
          <!-- 申请配对 -->
          <el-table-column prop="title" label="マッチング申請">
            <template slot-scope="scope">
              <el-button @click="addFried(scope.row)" type="text" size="small"
                >申請</el-button
              >
            </template>
          </el-table-column>
          <!-- 详细 -->
          <el-table-column label="プロフィール">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >プロフィール</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <!-- <userInfo ref="UpperDrawer" :data="userInfo" :media="mediaArr" :types="optionArr"></userInfo> -->
        <el-drawer :visible.sync="drawer" direction="rtl" size="50%">
        <div class="registerInfo">
          <div class="icon" v-if="userInfo.icon">
            <img :src="'/userupload/' + userInfo.icon" alt="">
          </div>
        <el-descriptions :label-style="LS" title="ユーザー情報" :column="1" style="margin-top:20px" border>
          <el-descriptions-item label-class-name="my-label" label="アイコン" v-if="userInfo.pic">  <el-avatar :size="80" :src="'/userupload/' + userInfo.pic"></el-avatar> </el-descriptions-item>
        	<el-descriptions-item label-class-name="my-label" label="ニックネーム">{{userInfo.nickname}} </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="登録属性" >{{ userInfo.loginattr == 1 ? 'クリエイター ' : '編集者'}} </el-descriptions-item>
           <el-descriptions-item label-class-name="my-label" v-if="userInfo.loginAttr == 1" label="参加したい工程" style="width:100px">
            <span style="padding: 0 5px" v-for="(item,index) in hopeInfo.checked">{{filterHopeType(item)}}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="媒体">
            <!-- <span style="padding: 0 5px" v-for="(item,index) in media">{{ item.media }}</span> -->
            <span style="padding: 0 5px" v-for="(item,index) in medias">{{item.media}}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="ジャンル">
            <span style="padding: 0 5px" v-for="(item,index) in classification">{{ item.typeb }}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="創作/エッセイ">
            <span v-if="userInfo.creativecap == 1" style="margin-left:15px">創作</span>
            <span v-else style="margin-left:15px">エッセイ</span>
          </el-descriptions-item>
          	<el-descriptions-item label-class-name="my-label" v-if="userInfo.theme" label="興味のある題材">
              <p v-for="(item,index) in userInfo.theme.split('#')" :key="index">{{item}}</p>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.introduction" label="自己PR">
              <p>{{userInfo.introduction}}</p>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.cartoon" label="好きな漫画">
				      <p>{{userInfo.cartoon}}</p>
			      </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.cartooner" label="好きな作家">
              <p>{{userInfo.cartooner}}</p>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.cute" label="萌え">
              <p>{{userInfo.cute}}</p>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.drawmethod && userInfo.loginAttr == 1" label="作画方法">
              <p>{{userInfo.drawmethod == 1 ? "アナログ" : "デジタル"}}</p>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.reward != '#' && userInfo.reward" label="受賞歴">
              <!-- <p v-for="(item,index) in userInfo.reward.split("#")" :key="index">{{index}}</p> -->
              <p>{{ userInfo.reward }}</p>
            </el-descriptions-item>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.experience && userInfo.loginattr == 1" label="漫画経験">
              <template v-if="userInfo.experience.length > 1">
                <span style="margin-left:15px">アシスタント</span>
                <span style="margin-left:15px">雑誌掲載</span>
              </template>
            <template  v-else-if="userInfo.experience == 1">
              <span v-if="userInfo.experience[0] == 1">アシスタント</span>
              <span style="margin-left:15px" v-else>雑誌掲載</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="achievements.length" label="製作実績">
            <div style="margin-bottom: 10px" class="achive_" v-for="(item,index) in achievements">
              <span v-if="item.selfProduVal" style="padding: 0 10px 0 0">{{item.selfProduVal == '1' ? '商業' : '自主制作'}}</span>
              <span v-if="item.colorVal" style="padding: 0 10px 0 0">{{item.colorVal == '1' ? 'カラー' : '白黒'}} </span>
              <span v-if="item.isSerialVal" style="padding: 0 10px 0 0">{{item.isSerialVal == '1' ? '読切' : '連載'}}</span>
              <span v-if=" item.serialSizeVal" style="padding: 0 10px 0 0">{{ item.serialSizeVal == '1' ? '縦スク' : '見開き' }}</span>
              <p style="padding: 0 10px 0 0" v-if="item.workName">{{ item.workName }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.author">{{ item.author }}</p>
              <p style="padding: 0 10px 0 0" v-if="item.desc">{{ item.desc }}</p>
          </div>
        </el-descriptions-item>
         <el-descriptions-item label-class-name="my-label" v-if="userInfo.introduce" label="ポートフォリオ">
            <p>{{userInfo.introduce}}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.adscartoon" label="広告マンガ製作依頼" >{{ userInfo.adscartoon == 1 ? '受ける ' : '受けない'}}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.drawmethod && userInfo.loginAttr == 2" label="所属編集部" >{{ userInfo.drawmethod == 1 ? '出版社編集部所属 ' : 'フリーランス'}}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.publish" label="海外への出版" >{{ userInfo.publish == 1 ? '希望する ' : '希望しない'}}</el-descriptions-item>
            </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import service from "@/service";
import userInfo from "../../../commpoents/userInfo/index.vue";
export default {
  components: {
    userInfo,
  },
  data() {
    return {
      LS: {
      'color': '#000',
      'font-weight':'700',
      'text-align': 'right',
      'width': '180px',
      'word-break': 'keep-all'
    },
      form: {
        type: "",
        media: "",
        creation: "",
        adv: "",
      },
      drawer: false,
      pic: "/userupload/",
      optionArr: [],
      mediaArr: [],
      tableData: [],
      userInfo: {},
      typebsArr: [],
      themeArr: [],
      media: [],
      medias: [],

      classification: [],
      achievements: [],
    };
  },
  mounted() {
    this.getType();
    this.getMedia();
  },
  watch: {
    userInfo: {
      handler(val) {
        let arr = val.typebs.split(",");
        this.typebsArr = []
        if (this.optionArr.length && arr.length) {
          this.optionArr.forEach((item, index) => {
            for (let i = 0; i < arr.length; i++) {
              if (item.id == arr[i]) {
                this.typebsArr.push(item);
              }
            }
          });
        }
        this.themeArr = val.theme.split("#");
      },
      deep: true,
    },
  },
  methods: {
    //获取分类
    getType() {
      service.personal
        .getTypeData()
        .then((res) => {
          if (res.status === 200) {
            this.optionArr = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMedia() {
      service.personal
        .getMedia()
        .then((res) => {
          if (res.status === 200) {
            this.mediaArr = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      let params = {
        type: this.form.type,
        media: this.form.media,
        create: this.form.creation,
        ads: this.form.adv,
      };
      this.tableData =  []
      service.personal
        .findUser(params)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length) {
              this.tableData = res.data;
            } else {
              this.$message.success("データなし");
            }
          }
        })
        .catch((err) => {});
    },
    resetSeach() {
      this.form.type = ''
      this.form.media = ''
      this.form.creation = ''
      this.form.adv = ''
      let params = {
        type: this.form.type,
        media: this.form.media,
        create: this.form.creation,
        ads: this.form.adv,
      };
      this.tableData = []
    //   service.personal
    //     .findUser(params)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         if (res.data.length) {
    //           this.tableData = res.data;
    //         } else {
    //           this.$message.success("データなし");
    //         }
    //       }
    //     })
    //     .catch((err) => {});

    },
    addFried(data) {
      console.log(data);
      let params = {
        fid: data.userid,
      };
      service.personal.addFriend(params).then((res) => {
        if (res.status === 200) {
          this.$message.success("マッチングリクエストを送信しました");
        } else if (res.status === 1) {
          this.$message.warning("既に友達であり、再度追加することはできません");
        }
      });
    },
    handleClick(data) {
      // this.$refs.UpperDrawer.drawer = true;
      this.drawer = true;
      // this.userInfo = {};
      this.achievements = [];
      this.medias = [];
      this.classification = []
      service.personal
        .getUserInfoData({ userid: data.userid })
        .then((res) => {
          if (res.status === 200) {
            this.userInfo = res.data.data;
            this.achievements = res.data.data.achievements ? res.data.data.achievements.split("#") : []
            this.achievements = this.achievements.map(item => {
              let arr = item.split(';')
              return {
                selfProduVal: arr[0],
                colorVal: arr[1],
                isSerialVal: arr[2],
                serialSizeVal: arr[3],
                workName: arr[4],
                author: arr[5],
                desc: arr[6],
              }
            })
            this.media = res.data.medias;
            this.medias = res.data.medias;
            this.classification = res.data.typebigs
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
  .my-label {
    background: #ddd !important;
  }
</style>
<style lang="scss" scoped>
.findUser {
  width: 100%;
  padding: 0 20px;
  .form-box {
    padding: 15px;
    ::v-deep.el-form-item {
      margin-bottom: 0;
    }
  }
  .btn-box {
    text-align: right;
    margin-right: 18%;
  }
  .tab-box {
    width: 97%;
    // padding: 15px;
    > span {
      font-size: 24px;
    }
    > div {
      margin-bottom: 15px;
    }
  }
  .wrap {
    padding: 15px;
    .augi {
      text-align: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      .img-box {  
        width: 80px;
        height: 80px;
        border-radius: 100%;
        display: block;
        span {
          display: block;
          margin: 10px 0 0 0;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
    }
    .type {
      margin: 40px 0 0 0;
      .contentBox {
        color: #606266;
        font-size: 14px;
        .content {
        padding: 0 10px;
        min-width: 20px;
        color: #606266;
      }
      }
     
    }
  }
}
// 注册信息
::v-deep.registerInfo {
  .icon {
    height: 160px;
    text-align: center;
    img {
      max-height: 100%;
    }
  }
  padding: 20px;
  ul {
    width: 100%;
    li {
      span {
        margin-left: 20px;
        font-size: 16px;
      }
      height: 32px;
      h4 {
        font-size: 18px;
      }
      div {
        padding-left: 20px;
      }
    }
  }
}
</style>
